import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';
import { menuGCType, menuMAIN } from 'src/app/globalcampo/config/menus-app.config';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { LoginService } from 'src/app/globalcampo/services/login.service';

@Component({
  selector: 'gc-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule],
})
export class MainMenuComponent implements OnInit, AfterViewInit{

  @ViewChild('mainMenuDropdown') mainMenuDropdown!: ElementRef;
  @ViewChild('mainMenuBackdrop') mainMenuBackdrop!: ElementRef;
  @ViewChild('dropdownContent') dropdownContent!: ElementRef;

  /**
 * Representa los nav links del menu
 */
  private readonly _navLinks:IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
 * Representa los links del dropdown
 */
  private readonly _dropdownLinks:IMenu[] = this.manageDropdownLinks();
  public get dropdownLinks(): IMenu[] {
    return this._dropdownLinks;
  }

  /**
   * Indica si se muestra o no el dropdown menu
   */
  private _showDropdown: boolean = false;
  public get showDropdown(): boolean {
    return this._showDropdown;
  }

    /**
   * Indica si se muestra o no el footer del dropdown menu
   */
  private _showDropdownFooter: boolean = false;
  public get showDropdownFooter(): boolean {
    return this._showDropdownFooter;
  }

  /**
   * Indica la altura del contenido del dropdown
   */
  private _dropdownContentHeight: string= '';

  constructor(
    private readonly _loginService: LoginService,
    private readonly _router: Router,
    private readonly _renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    /**
   * Comprueba si el usuario está logeado
   */
    this._loginService.isLoggedIn$.pipe(
      map((user) => {
        this._showDropdownFooter = !user;
      }),
    ).subscribe();

  }

  ngAfterViewInit(): void {
    /**
     * Observa los cambios en el tamaño del dropdownContent y ajusta la altura del mainMenuDropwdown.
     */
    const resizeObserver = new ResizeObserver((entries) => {
      if(this._showDropdown) {
        for(const entry of entries) {
          if(entry.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize[0];
            this.mainMenuDropdown.nativeElement.style.height = `${contentBoxSize.blockSize}px`;
          }
        }
      }
    });

    resizeObserver.observe(this.dropdownContent.nativeElement);

    /**
     * Este método configura un listener para los eventos 'click' en la ventana.
     * El listener del evento 'click' verifica si el dropdown debe ocultarse según el lugar donde ocurrió el clic.
     */
    document.addEventListener('click', (event) => {
      if(this._showDropdown) {
        const target = event.target as Node;
        const contentBtn = document.getElementById('gc-content-btn')?.contains(target);
        const mainMenuDropdown = this.mainMenuDropdown.nativeElement.contains(target);
        const btnLinks = document.querySelectorAll('a.gc-btn-link');

        if(!contentBtn && !mainMenuDropdown) {
          this._showDropdown = false;
          this.btnIconRotation();
        }

        btnLinks.forEach((btnLink) => {
          this.btnLinkContainsEvent(btnLink, event);
        });

      }
    });

    /**
     * Este método configura un listener para los eventos 'keydown' en la ventana.
     * El listener del evento 'keydown' verifica si el dropdown debe ocultarse según el key pulsado.
     */
    window.addEventListener('keydown', (event) => {
      this.eventListenerKeydown(event);
    });
  }

  /**
   * Comprueba si el elemento del enlace del botón dado contiene el objetivo del evento.
   * Si es así, oculta el menú desplegable y rota el icono del botón.
   *
   * @param btnLink - El elemento del enlace del botón a comprobar.
   * @param event - El evento a comprobar contra el elemento del enlace del botón.
   */
  private btnLinkContainsEvent(btnLink: Element, event: Event) {
    if (btnLink.contains(event.target as Node)) {
      this._showDropdown = false;
      this.btnIconRotation();
    }
  }

  /**
   * Maneja el evento keydown para el componente del menú principal.
   */
  private eventListenerKeydown(event: KeyboardEvent) {
    if (this._showDropdown && event.key === 'Escape') {
        this._showDropdown = false;
        this.btnIconRotation();
    }
  }

    /**
   * Recupera un array de elementos de menú de navegación.
   *
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
    private manageNavLinks(): IMenu[] {
      const menu: menuGCType = menuMAIN;
      const inicio = menu['inicio'];
      const cca = menu['cca'];
      const lonjas = menu['lonjas'];
      const globalclima = menu['globalclima'];
      return [inicio, cca, lonjas, globalclima];
    }

    /**
   * Recupera un array de elementos de menú de navegación del dropdown.
   *
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
    private manageDropdownLinks(): IMenu[] {
      const menu: menuGCType = menuMAIN;
      const gicoop = menu['gicoop'];
      const noticias = menu['noticias'];
      const pac = menu['pac'];
      const sar = menu['sar'];
      return [gicoop, noticias, pac, sar];
    }

    /**
     * Navega a la URL especificada.
     *
     * @param menu - El objeto de menú que contiene la URL a la que navegar.
     * @returns Una Promesa que se resuelve cuando se completa la navegación.
     */
    async navigationUrl(menu: IMenu) {
      const link: string = menu.link ?? '';
      await this._router.navigate([link]);
    }

    /**
     * Determina si la URL dada está activa en función de la URL actual del enrutador.
     *
     * @param url - La URL a verificar si está activa.
     * @returns `true` si la URL está activa, `false` en caso contrario.
     */
    public linkActive(url: string): boolean {
      const urlPrefix = url?.split('/')[1];
      return this._router.url.includes(urlPrefix);
    }

    /**
     * Abre el menú desplegable y rota el icono de flecha del usuario.
     */
    public toggleDropdown() {
      this._dropdownContentHeight = this.dropdownContent.nativeElement.offsetHeight;
      this._showDropdown = !this._showDropdown;
      this.btnIconRotation();
    }

    /**
     * Rota el icono del botón basado en el estado del dropdown.
     */
    private btnIconRotation() {
      const btnArrow = document.getElementById('gc-btn-icon') as HTMLElement;
      btnArrow.style.transform = this._showDropdown ? 'rotate(180deg)' : 'rotate(0deg)';
      this._renderer.setStyle(this.mainMenuDropdown.nativeElement, 'height', this._showDropdown ? `${this._dropdownContentHeight}px` : '0px');
    }

}
