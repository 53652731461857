<ion-header #header id="gc-header-main" [ngClass]="{ 'ion-hide-md-down': splitPaneContent() !== 'main' && splitPaneContent() !== 'none'}">
    @if(authorized){
    <gc-main-menu-mobile class="ion-hide-md-up"></gc-main-menu-mobile>
    }
    <div class="gc-header-main-logo" routerLink="/">
      <img class="gc-logo-img" src="{{logo}}" alt="Logotipo" />
      <h1 class="gc-logo-title">{{ 'GLOBALCAMPO' | translate }}</h1>
    </div>
    @if(authorized){
    <gc-main-menu class="ion-hide-md-down"></gc-main-menu>
    }
    <div class="gc-header-main-user">
      @if(authorized){
        <ion-icon class="gc-user-notif" name="gc-user-notif-off"></ion-icon>
      }
      <gc-user-profile></gc-user-profile>
    </div>
</ion-header>