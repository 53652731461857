<div class="modal-header">
  <h3 class="header-title">{{ 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.ACTIVIDADES' | translate}}</h3>
  <ion-icon  class="header-cancel" name="close-outline" (click)="dismisDialog()" (keydown.enter)="dismisDialog()"></ion-icon>
</div>

<div class="modal-content">
  <!-- Actividades principales -->
  <div class="content-actividades">
    <h3 class="actividades-title">{{ 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.TRATAMIENTOS_FITOS' | translate}}</h3>
    <div class="actividades-list">
      @for (el of actividadesPrincipales; track el) {
        <div  class="list-item" (click)="selected(el)" (keydown.enter)="selected(el)" [ngClass]="{'unknow' : comprobarUrl(el)}">
          <div class="item-icon">
            <ion-icon class="icon-default" [name]="el.icon + '-outline'"></ion-icon>
            <ion-icon class="icon-hover" [name]="el.icon"></ion-icon>
          </div>
          <p class="item-label">{{ el.descripcion | translate }}</p>
        </div>
      }
    </div>
  </div>
  <!-- Actividades Secundarias -->
  <div class="content-actividades">
    <h3 class="actividades-title">{{ 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.OTRAS_ACTIVIDADES' | translate}}</h3>
    <div class="actividades-list">
    @for (el of actividadesSecundarias; track el) {
      <div class="list-item" (click)="selected(el)" (keydown.enter)="selected(el)" [ngClass]="{'unknow' : comprobarUrl(el)}">
        <div class="item-icon">
            <ion-icon class="icon-default" [name]="el.icon + '-outline'"></ion-icon>
            <ion-icon class="icon-hover" [name]="el.icon"></ion-icon>
        </div>
        <p class="item-label">{{ el.descripcion | translate }}</p>
      </div>
    }
    </div>
  </div>
</div>
