import { IMenu } from '../interfaces/menu-item.interface';
import { urls } from './urls';

export type menuGCType = {[key: string]: IMenu};

export const menuMAIN: menuGCType = {
  inicio: { link: urls.inicio, icon: 'home-outline', iconHover: 'home', title: 'MENU.MAIN.INICIO'},
  cca: { link: urls.cca.instalaciones, icon: 'book-outline', iconHover: 'book', title: 'MENU.MAIN.CCA'},
  lonjas: { link: urls.lonjas, icon: 'bar-chart-outline', iconHover: 'bar-chart', title: 'MENU.MAIN.LONJAS'},
  globalclima: { link: urls.globalclima, icon: 'rainy-outline', iconHover: 'rainy', title: 'MENU.MAIN.GLOBALCLIMA'},
  gicoop: { link: urls.gicoop, icon: 'gc-gicoop-outline', iconHover: 'gc-gicoop', title: 'MENU.MAIN.DROPDOWN.GICOOP_PLUS.TITLE', subTitle: 'MENU.MAIN.DROPDOWN.GICOOP_PLUS.DESC'},
  noticias: { link: urls.noticias, icon: 'gc-ad-news-outline', iconHover: 'gc-ad-news', title: 'MENU.MAIN.DROPDOWN.NOTICIAS.TITLE', subTitle: 'MENU.MAIN.DROPDOWN.NOTICIAS.DESC'},
  pac: { link: urls.pac, icon: 'gc-chat-comment-outline', iconHover: 'gc-chat-comment', title: 'MENU.MAIN.DROPDOWN.PAC_AYUDAS.TITLE',
    subTitle: 'MENU.MAIN.DROPDOWN.PAC_AYUDAS.DESC'},
  sar: { link: urls.sar, icon: 'gc-weather-drop-outline', iconHover: 'gc-weather-drop', title: 'MENU.MAIN.DROPDOWN.SAR.TITLE', subTitle: 'MENU.MAIN.DROPDOWN.SAR.DESC'},
};

export const menuCCA: menuGCType = {
  dashboard: { link: urls.cca.dashboard, icon: 'gc-user-home-outline', iconHover: 'gc-user-home', title: 'CCA.MENU.PANEL_CONTROL'},
  actividades: { link: urls.cca.actividades, icon: 'gc-business-clipboard-outline', iconHover: 'gc-business-clipboard', title: 'CCA.MENU.ACTIVIDADES'},
  titular: { link: urls.cca.titular, icon: 'gc-farming-farmer-outline', iconHover: 'gc-farming-farmer', title: 'CCA.MENU.TITULAR'},
  explotacion: { link: urls.cca.general, icon: 'gc-farming-barn-outline', iconHover: 'gc-farming-barn', title: 'CCA.MENU.EXPLOTACION'},
  trabajadores: { link: urls.cca.trabajadores, icon: 'gc-user-multiple-outline', iconHover: 'gc-user-multiple', title: 'CCA.MENU.TRABAJADORES'},
  parcelario: { link: urls.cca.parcelas, icon: 'gc-map-outline', iconHover: 'gc-map', title: 'CCA.MENU.PARCELARIO'},
  instalaciones: { link: urls.cca.instalaciones, title: 'CCA.MENU.INSTALACION'},
  explotaciones: { link: urls.cca.busqueda, title: 'CCA.MENU.EXPLOTACION'},
  inicio: { link: urls.inicio, title: 'CCA.MENU.SALIR'},
};
