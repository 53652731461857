<ion-icon id="gc-main-menu-btn" name="gc-user-menu" (click)="toggleMenu()"  (keydown.enter)="toggleMenu()"></ion-icon>

<div id="gc-main-menu-content" #mainMenuContent>
  <div id="gc-main-menu-content-header" routerLink="/" #contentHeader>
    <img class="header-img" src="{{logo}}" alt=""/>
    <h2 class="header-title">{{ 'GLOBALCAMPO' | translate }}</h2>
  </div>
  <div id="gc-main-menu-content-content">
      <div class="gc-content-nav">
        @for(navLink of navLinks; track navLink) {
          <a class="gc-nav-link" routerLink="{{navLink.link}}" routerLinkActive="active" (click)="navigationUrl(navLink)" (keydown.enter)="navigationUrl(navLink)" >
            <span class="link-icon">
              <ion-icon class="icon-outline" name="{{ navLink.icon }}"></ion-icon>
              <ion-icon class="icon-solid" name="{{ navLink.iconHover }}"></ion-icon>
            </span>
            <h5 class="link-title">{{ navLink.title | translate }}</h5>
          </a>
        }
      </div>
  </div>
</div>

<div id="gc-main-menu-backdrop" #mainMenuBackdrop></div>