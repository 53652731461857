import { addIcons } from 'ionicons';
import { IGlobalcampoIcons, TGlobalcampoIcon, TIconType } from '../interfaces/globalcampo.icon.interface';

const assetsGCPath = '/assets/icon/gc/';
const assetsActivitiesPath = '/assets/icon/gc/activities/';

const buildGCPath = (folder: string, name: string) => `${assetsGCPath}${folder}/${name}.svg`;
const buildActivitiesPath = (folder: string, name: string) => `${assetsActivitiesPath}${folder}/${name}.svg`;


//activitiesFolder
const gcActividadAgrariaFolder = 'actividad-agraria';
const gcActividadAgrariaName = 'gc-actividad-agraria';
const gcActividadAgrariaOutlineName = `${gcActividadAgrariaName}-outline`;
export const gcActividadAgrariaSolid = { [gcActividadAgrariaName]: buildActivitiesPath(gcActividadAgrariaFolder, gcActividadAgrariaName) };
export const gcActividadAgrariaOutline = { [gcActividadAgrariaOutlineName]: buildActivitiesPath(gcActividadAgrariaFolder, gcActividadAgrariaOutlineName) };
const gcAnaliticaFolder = 'analitica';
const gcAnaliticaName = 'gc-analitica';
const gcAnaliticaOutlineName = `${gcAnaliticaName}-outline`;
export const gcAnaliticaSolid = { [gcAnaliticaName]: buildActivitiesPath(gcAnaliticaFolder, gcAnaliticaName) };
export const gcAnaliticaOutline = { [gcAnaliticaOutlineName]: buildActivitiesPath(gcAnaliticaFolder, gcAnaliticaOutlineName) };
const gcComercializacionVentaFolder = 'comercializacion-venta-directa';
const gcComercializacionVentaName = 'gc-comercializacion-venta';
const gcComcercializacionVentaOutlineName = `${gcComercializacionVentaName}-outline`;
export const gcComcercializacionVentaSolid = { [gcComercializacionVentaName]: buildActivitiesPath(gcComercializacionVentaFolder, gcComercializacionVentaName) };
export const gcComcercializacionVentaOutline = { [gcComcercializacionVentaOutlineName]: buildActivitiesPath(gcComercializacionVentaFolder, gcComcercializacionVentaOutlineName) };
const cosechaFolder = 'cosecha';
const gcCosechaName = 'gc-cosecha';
const gcCosechaOutlineName = `${gcCosechaName}-outline`;
export const gcCosechaSolid = { [gcCosechaName]: buildActivitiesPath(cosechaFolder, gcCosechaName) };
export const gcCosechaOutline = { [gcCosechaOutlineName]: buildActivitiesPath(cosechaFolder, gcCosechaOutlineName) };
const gcDatosCubiertaFolder = 'datos-cubierta';
const gcDatosCubiertaName = 'gc-datos-cubierta';
const gcDatosCubiertaOutlineName = `${gcDatosCubiertaName}-outline`;
export const gcDatosCubiertaSolid = { [gcDatosCubiertaName]: buildActivitiesPath(gcDatosCubiertaFolder, gcDatosCubiertaName) };
export const gcDatosCubiertaOutline = { [gcDatosCubiertaOutlineName]: buildActivitiesPath(gcDatosCubiertaFolder, gcDatosCubiertaOutlineName) };
const gcEdificacionesFolder = 'edificaciones-instalaciones';
const gcEdificacionesName = 'gc-edificaciones';
const gcEdificacionesOutlineName = `${gcEdificacionesName}-outline`;
export const gcEdificacionesSolid = { [gcEdificacionesName]: buildActivitiesPath(gcEdificacionesFolder, gcEdificacionesName) };
export const gcEdificacionesOutline = { [gcEdificacionesOutlineName]: buildActivitiesPath(gcEdificacionesFolder, gcEdificacionesOutlineName) };
const gcEnergiaUtilizadaFolder = 'energia-utilizada';
const gcEnergiaUtilizadaName = 'gc-energia-utilizada';
const gcEnergiaUtilizadaOutlineName = `${gcEnergiaUtilizadaName}-outline`;
export const gcEnergiaUtilizadaSolid = { [gcEnergiaUtilizadaName]: buildActivitiesPath(gcEnergiaUtilizadaFolder, gcEnergiaUtilizadaName) };
export const gcEnergiaUtilizadaOutline = { [gcEnergiaUtilizadaOutlineName]: buildActivitiesPath(gcEnergiaUtilizadaFolder, gcEnergiaUtilizadaOutlineName) };
const gcFertilizacionFolder = 'fertilizacion';
const gcFertilizacionName = 'gc-fertilizacion';
const gcFertilizacionOutlineName = `${gcFertilizacionName}-outline`;
export const gcFertilizacionSolid = { [gcFertilizacionName]: buildActivitiesPath(gcFertilizacionFolder, gcFertilizacionName) };
export const gcFertilizacionOutline = { [gcFertilizacionOutlineName]: buildActivitiesPath(gcFertilizacionFolder, gcFertilizacionOutlineName) };
const gcFitosanitarioFolder = 'fitosanitario';
const gcFitosanitarioName = 'gc-fitosanitario';
const gcFitosanitarioOutlineName = `${gcFitosanitarioName}-outline`;
export const gcFitosanitarioSolid = { [gcFitosanitarioName]: buildActivitiesPath(gcFitosanitarioFolder, gcFitosanitarioName) };
export const gcFitosanitarioOutline = { [gcFitosanitarioOutlineName]: buildActivitiesPath(gcFitosanitarioFolder, gcFitosanitarioOutlineName) };
const gcPastoreoFolder = 'pastoreo';
const gcPastoreoName = 'gc-pastoreo';
const gcPastoreoOutlineName = `${gcPastoreoName}-outline`;
export const gcPastoreoSolid = { [gcPastoreoName]: buildActivitiesPath(gcPastoreoFolder, gcPastoreoName) };
export const gcPastoreoOutline = { [gcPastoreoOutlineName]: buildActivitiesPath(gcPastoreoFolder, gcPastoreoOutlineName) };
const gcPlanAbonadoFolder = 'plan-abonado';
const gcPlanAbonadoName = 'gc-plan-abonado';
const gcPlanAbonadoOutlineName = `${gcPlanAbonadoName}-outline`;
export const gcPlanAbonadoSolid = { [gcPlanAbonadoName]: buildActivitiesPath(gcPlanAbonadoFolder, gcPlanAbonadoName) };
export const gcPlanAbonadoOutline = { [gcPlanAbonadoOutlineName]: buildActivitiesPath(gcPlanAbonadoFolder, gcPlanAbonadoOutlineName) };
const gcPostCosechaFolder = 'post-cosecha';
const gcPostCosechaName = 'gc-post-cosecha';
const gcPostCosechaOutlineName = `${gcPostCosechaName}-outline`;
export const gcPostCosechaSolid = { [gcPostCosechaName]: buildActivitiesPath(gcPostCosechaFolder, gcPostCosechaName) };
export const gcPostCosechaOutline = { [gcPostCosechaOutlineName]: buildActivitiesPath(gcPostCosechaFolder, gcPostCosechaOutlineName) };
const gcUsoSemillaTratadaFolder = 'semilla-tratada';
const gcUsoSemillaTratadaName = 'gc-uso-semilla-tratada';
const gcUsoSemillaTratadaOutlineName = `${gcUsoSemillaTratadaName}-outline`;
export const gcUsoSemillaTratadaSolid = { [gcUsoSemillaTratadaName]: buildActivitiesPath(gcUsoSemillaTratadaFolder, gcUsoSemillaTratadaName) };
export const gcUsoSemillaTratadaOutline = { [gcUsoSemillaTratadaOutlineName]: buildActivitiesPath(gcUsoSemillaTratadaFolder, gcUsoSemillaTratadaOutlineName) };
const gcSiembraPlantacionFolder = 'siembra-plantacion';
const gcSiembraPlantacionName = 'gc-siembra-plantacion';
const gcSiembraPlantacionOutlineName = `${gcSiembraPlantacionName}-outline`;
export const gcSiembraPlantacionSolid = { [gcSiembraPlantacionName]: buildActivitiesPath(gcSiembraPlantacionFolder, gcSiembraPlantacionName) };
export const gcSiembraPlantacionOutline = { [gcSiembraPlantacionOutlineName]: buildActivitiesPath(gcSiembraPlantacionFolder, gcSiembraPlantacionOutlineName) };

//addFolder
const addFolder = 'add';
const gcAddFolderName = 'gc-add-folder';
export const gcAddFolderSolid = { [gcAddFolderName]: buildGCPath(addFolder, gcAddFolderName) };
const gcAddMapName = 'gc-add-map';
export const gcAddMapSolid = { [gcAddMapName]: buildGCPath(addFolder, gcAddMapName) };
const gcAddUserName = 'gc-add-user';
export const gcAddUserSolid = { [gcAddUserName]: buildGCPath(addFolder, gcAddUserName) };
const gcAddTracktorName = 'gc-add-tracktor';
export const gcAddTracktorSolid = { [gcAddTracktorName]: buildGCPath(addFolder, gcAddTracktorName) };

//advertisementFolder
const advertisementFolder = 'advertisement';
const gcAdNewsName = 'gc-ad-news';
const gcAdNewsOutlineName = `${gcAdNewsName}-outline`;
export const gcAdNewsSolid = { [gcAdNewsName]: buildGCPath(advertisementFolder, gcAdNewsName) };
export const gcAdNewsOutline = { [gcAdNewsOutlineName]: buildGCPath(advertisementFolder, gcAdNewsOutlineName) };

//arrowFolder
const arrowFolder = 'arrow';
const gcArrowDownName = 'gc-arrow-down';
export const gcArrowDownSolid = { [gcArrowDownName]: buildGCPath(arrowFolder, gcArrowDownName) };
const gcArrowOutName = 'gc-arrow-out';
export const gcArrowOutSolid = { [gcArrowOutName]: buildGCPath(arrowFolder, gcArrowOutName) };
const gcArrowDoubleName= 'gc-arrow-double';
export const gcArrowDoubleSolid = { [gcArrowDoubleName]: buildGCPath(arrowFolder, gcArrowDoubleName) };
const gcArrowBackwardName = 'gc-arrow-backward';
export const gcArrowBackwardSolid = { [gcArrowBackwardName]: buildGCPath(arrowFolder, gcArrowBackwardName) };
const gcArrowForwardName = 'gc-arrow-forward';
export const gcArrowForwardSolid = { [gcArrowForwardName]: buildGCPath(arrowFolder, gcArrowForwardName) };
const gcArrowLeftName = 'gc-arrow-left';
export const gcArrowLeftSolid = { [gcArrowLeftName]: buildGCPath(arrowFolder, gcArrowLeftName) };
const gcArrowRightName = 'gc-arrow-right';
export const gcArrowRightSolid = { [gcArrowRightName]: buildGCPath(arrowFolder, gcArrowRightName) };

//businessFolder
const businessFolder = 'business';
const gcBusinessIdName = 'gc-business-id-name';
const gcBusinessIdOutlineName = `${gcBusinessIdName}-outline`;
export const gcBusinessIdNameSolid = { [gcBusinessIdName]: buildGCPath(businessFolder, gcBusinessIdName) };
export const gcBusinessIdNameOutline = { [gcBusinessIdOutlineName]: buildGCPath(businessFolder, gcBusinessIdOutlineName) };
const gcBusinessIdNumberName = 'gc-business-id-number';
const gcBusinessIdNumberOutlineName = `${gcBusinessIdNumberName}-outline`;
export const gcBusinessIdNumberSolid = { [gcBusinessIdNumberName]: buildGCPath(businessFolder, gcBusinessIdNumberName) };
export const gcBusinessIdNumberOutline = { [gcBusinessIdNumberOutlineName]: buildGCPath(businessFolder, gcBusinessIdNumberOutlineName) };
const gcBusinessFolderName = 'gc-business-folder';
export const gcBusinessFolderSolid = { [gcBusinessFolderName]: buildGCPath(businessFolder, gcBusinessFolderName) };
const gcBusinessClipboardName = 'gc-business-clipboard';
const gcBusinessClipboardOutlineName = `${gcBusinessClipboardName}-outline`;
export const gcBusinessClipboardSolid = { [gcBusinessClipboardName]: buildGCPath(businessFolder, gcBusinessClipboardName) };
export const gcBusinessClipboardOutline = { [gcBusinessClipboardOutlineName]: buildGCPath(businessFolder, gcBusinessClipboardOutlineName) };

//chatFolder
const chatFolder = 'chat';
const gcChatCommentName = 'gc-chat-comment';
const gcChatCommentOutlineName = `${gcChatCommentName}-outline`;
export const gcChatCommentSolid = { [gcChatCommentName]: buildGCPath(chatFolder, gcChatCommentName) };
export const gcChatCommentOutline = { [gcChatCommentOutlineName]: buildGCPath(chatFolder, gcChatCommentOutlineName) };

//corporationFolder
const corporationFolder = 'corporation';
const gcGicoopName = 'gc-gicoop';
const gcGicoopOutlineName = `${gcGicoopName}-outline`;
export const gcGicoopSolid = { [gcGicoopName]: buildGCPath(corporationFolder, gcGicoopName) };
export const gcGicoopOutline = { [gcGicoopOutlineName]: buildGCPath(corporationFolder, gcGicoopOutlineName) };

//farmingFolder
const farmingFolder = 'farming';
const gcFarmingFarmerName = 'gc-farming-farmer';
const gcFarmingFarmerOutlineName = `${gcFarmingFarmerName}-outline`;
export const gcFarmingFarmerSolid = { [gcFarmingFarmerName]: buildGCPath(farmingFolder, gcFarmingFarmerName) };
export const gcFarmingFarmerOutline = { [gcFarmingFarmerOutlineName]: buildGCPath(farmingFolder, gcFarmingFarmerOutlineName) };
const gcFarmingBarnName = 'gc-farming-barn';
const gcFarmingBarnOutlineName = `${gcFarmingBarnName}-outline`;
export const gcFarmingBarnSolid = { [gcFarmingBarnName]: buildGCPath(farmingFolder, gcFarmingBarnName) };
export const gcFarmingBarnOutline = { [gcFarmingBarnOutlineName]: buildGCPath(farmingFolder, gcFarmingBarnOutlineName) };
const gcFarmingGrapesName = 'gc-farming-grapes';
const gcFarmingGrapesOutlineName = `${gcFarmingGrapesName}-outline`;
export const gcFarmingGrapesSolid = { [gcFarmingGrapesName]: buildGCPath(farmingFolder, gcFarmingGrapesName) };
export const gcFarmingGrapesOutline = { [gcFarmingGrapesOutlineName]: buildGCPath(farmingFolder, gcFarmingGrapesOutlineName) };

//mapFolder
const mapFolder = 'map';
const gcMapName = 'gc-map';
const gcMapOutlineName = `${gcMapName}-outline`;
export const gcMapSolid = { [gcMapName]: buildGCPath(mapFolder, gcMapName) };
export const gcMapOutline = { [gcMapOutlineName]: buildGCPath(mapFolder, gcMapOutlineName) };

//userFolder
const userFolder = 'user';
const gcUserName = 'gc-user-profile';
const gcUserOutlineName = `${gcUserName}-outline`;
export const gcUserSolid = { [gcUserName]: buildGCPath(userFolder, gcUserName) };
export const gcUserOutline = { [gcUserOutlineName]: buildGCPath(userFolder, gcUserOutlineName) };
const gcUserMenuName = 'gc-user-menu';
export const gcUserMenuSolid = { [gcUserMenuName]: buildGCPath(userFolder, gcUserMenuName) };
const gcUserNotifOFFName = 'gc-user-notif-off';
export const gcUserNotifOFFSolid = { [gcUserNotifOFFName]: buildGCPath(userFolder, gcUserNotifOFFName) };
const gcUserNotifONName = 'gc-user-notif-on';
export const gcUserNotifONSolid = { [gcUserNotifONName]: buildGCPath(userFolder, gcUserNotifONName) };
const gcUserSettingsName = 'gc-user-settings';
export const gcUserSettingsSolid = { [gcUserSettingsName]: buildGCPath(userFolder, gcUserSettingsName) };
const gcUserPlusName = 'gc-user-plus';
export const gcUserPlusSolid = { [gcUserPlusName]: buildGCPath(userFolder, gcUserPlusName) };
const gcUserRefreshName = 'gc-user-refresh';
export const gcUserRefreshSolid = { [gcUserRefreshName]: buildGCPath(userFolder, gcUserRefreshName) };
const gcUserHomeName = 'gc-user-home';
const gcUserHomeOutlineName = `${gcUserHomeName}-outline`;
export const gcUserHomeSolid = { [gcUserHomeName]: buildGCPath(userFolder, gcUserHomeName) };
export const gcUserHomeOutline = { [gcUserHomeOutlineName]: buildGCPath(userFolder, gcUserHomeOutlineName) };
const gcUserMultipleName = 'gc-user-multiple';
const gcUserMultipleOutlineName = `${gcUserMultipleName}-outline`;
export const gcUserMultipleSolid = { [gcUserMultipleName]: buildGCPath(userFolder, gcUserMultipleName) };
export const gcUserMultipleOutline = { [gcUserMultipleOutlineName]: buildGCPath(userFolder, gcUserMultipleOutlineName) };
const gcInputLockSolidName = 'gc-user-lock';
export const gcInputLockSolid = { [gcInputLockSolidName]: buildGCPath(userFolder, gcInputLockSolidName) };

//weatherFolder
const weatherFolder = 'weather';
const gcWeatherDropName = 'gc-weather-drop';
const gcWeatherDropOutlineName = `${gcWeatherDropName}-outline`;
export const gcWeatherDropSolid = { [gcWeatherDropName]: buildGCPath(weatherFolder, gcWeatherDropName) };
export const gcWeatherDropOutline = { [gcWeatherDropOutlineName]: buildGCPath(weatherFolder, gcWeatherDropOutlineName) };
const gcWeatherMoonName = 'gc-weather-moon';
const gcWeatherMoonOutlineName = `${gcWeatherMoonName}-outline`;
export const gcWeatherMoonSolid = { [gcWeatherMoonName]: buildGCPath(weatherFolder, gcWeatherMoonName) };
export const gcWeatherMoonOutline = { [gcWeatherMoonOutlineName]: buildGCPath(weatherFolder, gcWeatherMoonOutlineName) };
const gcWeatherSunName = 'gc-weather-sun';
const gcWeatherSunOutlineName = `${gcWeatherSunName}-outline`;
export const gcWeatherSunSolid = { [gcWeatherSunName]: buildGCPath(weatherFolder, gcWeatherSunName) };
export const gcWeatherSunOutline = { [gcWeatherSunOutlineName]: buildGCPath(weatherFolder, gcWeatherSunOutlineName) };

export const globalcampoIconos: IGlobalcampoIcons = {

    addFolder: {
        solid: {
            name: gcAddFolderName,
            iconData: gcAddFolderSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    addMap: {
        solid: {
            name: gcAddMapName,
            iconData: gcAddMapSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    addUser: {
        solid: {
            name: gcAddUserName,
            iconData: gcAddUserSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    addTracktor: {
        solid: {
            name: gcAddTracktorName,
            iconData: gcAddTracktorSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    adNews: {
        solid: {
            name: gcAdNewsName,
            iconData: gcAdNewsSolid,
        },
        outline: {
            name: gcAdNewsOutlineName,
            iconData: gcAdNewsOutline,
        },
    },
    arrowDown: {
        solid: {
            name: gcArrowDownName,
            iconData: gcArrowDownSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowOut: {
        solid: {
            name: gcArrowOutName,
            iconData: gcArrowOutSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowDouble: {
        solid: {
            name: gcArrowDoubleName,
            iconData: gcArrowDoubleSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowBackward: {
        solid: {
            name: gcArrowBackwardName,
            iconData: gcArrowBackwardSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowForward: {
        solid: {
            name: gcArrowForwardName,
            iconData: gcArrowForwardSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowLeft: {
        solid: {
            name: gcArrowLeftName,
            iconData: gcArrowLeftSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowRight: {
        solid: {
            name: gcArrowRightName,
            iconData: gcArrowRightSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    businesIdName: {
        solid: {
            name: gcBusinessIdName,
            iconData: gcBusinessIdNameSolid,
        },
        outline: {
            name: gcBusinessIdOutlineName,
            iconData: gcBusinessIdNameOutline,
        },
    },
    businesIdNumber: {
        solid: {
            name: gcBusinessIdNumberName,
            iconData: gcBusinessIdNumberSolid,
        },
        outline: {
            name: gcBusinessIdNumberOutlineName,
            iconData: gcBusinessIdNumberOutline,
        },
    },
    businessFolder: {
        solid: {
            name: gcBusinessFolderName,
            iconData: gcBusinessFolderSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    businessClipboard: {
        solid: {
            name: gcBusinessClipboardName,
            iconData: gcBusinessClipboardSolid,
        },
        outline: {
            name: gcBusinessClipboardOutlineName,
            iconData: gcBusinessClipboardOutline,
        },
    },
    chatComment: {
        solid: {
            name: gcChatCommentName,
            iconData: gcChatCommentSolid,
        },
        outline: {
            name: gcChatCommentOutlineName,
            iconData: gcChatCommentOutline,
        },
    },
    gicoop: {
        solid: {
            name: gcGicoopName,
            iconData: gcGicoopSolid,
        },
        outline: {
            name: gcGicoopOutlineName,
            iconData: gcGicoopOutline,
        },
    },
    farmingFarmer: {
        solid: {
            name: gcFarmingFarmerName,
            iconData: gcFarmingFarmerSolid,
        },
        outline: {
            name: gcFarmingFarmerOutlineName,
            iconData: gcFarmingFarmerOutline,
        },
    },
    farmingBarn: {
        solid: {
            name: gcFarmingBarnName,
            iconData: gcFarmingBarnSolid,
        },
        outline: {
            name: gcFarmingBarnOutlineName,
            iconData: gcFarmingBarnOutline,
        },
    },
    farmingGrapes: {
        solid: {
            name: gcFarmingGrapesName,
            iconData: gcFarmingGrapesSolid,
        },
        outline: {
            name: gcFarmingGrapesOutlineName,
            iconData: gcFarmingGrapesOutline,
        },
    },
    map: {
        solid: {
            name: gcMapName,
            iconData: gcMapSolid,
        },
        outline: {
            name: gcMapOutlineName,
            iconData: gcMapOutline,
        },
    },
    userProfile: {
        solid: {
            name: gcUserName,
            iconData: gcUserSolid,
        },
        outline: {
            name: gcUserOutlineName,
            iconData: gcUserOutline,
        },
    },
    userMenu: {
        solid: {
            name: gcUserMenuName,
            iconData: gcUserMenuSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userNotifOFF: {
        solid: {
            name: gcUserNotifOFFName,
            iconData: gcUserNotifOFFSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userNotifON: {
        solid: {
            name: gcUserNotifONName,
            iconData: gcUserNotifONSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userSettings: {
        solid: {
            name: gcUserSettingsName,
            iconData: gcUserSettingsSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userPlus: {
        solid: {
            name: gcUserPlusName,
            iconData: gcUserPlusSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userRefresh: {
        solid: {
            name: gcUserRefreshName,
            iconData: gcUserRefreshSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userHome: {
        solid: {
            name: gcUserHomeName,
            iconData: gcUserHomeSolid,
        },
        outline: {
            name: gcUserHomeOutlineName,
            iconData: gcUserHomeOutline,
        },
    },
    userMultiple: {
        solid: {
            name: gcUserMultipleName,
            iconData: gcUserMultipleSolid,
        },
        outline: {
            name: gcUserMultipleOutlineName,
            iconData: gcUserMultipleOutline,
        },
    },
    userLock: {
        solid: {
            name: gcInputLockSolidName,
            iconData: gcInputLockSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    weatherDrop: {
        solid: {
            name: gcWeatherDropName,
            iconData: gcWeatherDropSolid,
        },
        outline: {
            name: gcWeatherDropOutlineName,
            iconData: gcWeatherDropOutline,
        },
    },
    weatherSun: {
        solid: {
            name: gcWeatherSunName,
            iconData: gcWeatherSunSolid,
        },
        outline: {
            name: gcWeatherSunOutlineName,
            iconData: gcWeatherSunOutline,
        },
    },
    weatherMoon: {
        solid: {
            name: gcWeatherMoonName,
            iconData: gcWeatherMoonSolid,
        },
        outline: {
            name: gcWeatherMoonOutlineName,
            iconData: gcWeatherMoonOutline,
        },
    },
    cosecha: {
        solid: {
            name: gcCosechaName,
            iconData: gcCosechaSolid,
        },
        outline: {
            name: gcCosechaOutlineName,
            iconData: gcCosechaOutline,
        },
    },
    fitosanitario: {
        solid: {
            name: gcFitosanitarioName,
            iconData: gcFitosanitarioSolid,
        },
        outline: {
            name: gcFitosanitarioOutlineName,
            iconData: gcFitosanitarioOutline,
        },
    },
    edificaciones: {
        solid: {
            name: gcEdificacionesName,
            iconData: gcEdificacionesSolid,
        },
        outline: {
            name: gcEdificacionesOutlineName,
            iconData: gcEdificacionesOutline,
        },
    },
    usoSemillaTratada: {
        solid: {
            name: gcUsoSemillaTratadaName,
            iconData: gcUsoSemillaTratadaSolid,
        },
        outline: {
            name: gcUsoSemillaTratadaOutlineName,
            iconData: gcUsoSemillaTratadaOutline,
        },
    },
    postCosecha: {
        solid: {
            name: gcPostCosechaName,
            iconData: gcPostCosechaSolid,
        },
        outline: {
            name: gcPostCosechaOutlineName,
            iconData: gcPostCosechaOutline,
        },
    },
    fertilizacion: {
        solid: {
            name: gcFertilizacionName,
            iconData: gcFertilizacionSolid,
        },
        outline: {
            name: gcFertilizacionOutlineName,
            iconData: gcFertilizacionOutline,
        },
    },
    analitica: {
        solid: {
            name: gcAnaliticaName,
            iconData: gcAnaliticaSolid,
        },
        outline: {
            name: gcAnaliticaOutlineName,
            iconData: gcAnaliticaOutline,
        },
    },
    siembraPlantacion: {
        solid: {
            name: gcSiembraPlantacionName,
            iconData: gcSiembraPlantacionSolid,
        },
        outline: {
            name: gcSiembraPlantacionOutlineName,
            iconData: gcSiembraPlantacionOutline,
        },
    },
    comercializacionVenta: {
        solid: {
            name: gcComercializacionVentaName,
            iconData: gcComcercializacionVentaSolid,
        },
        outline: {
            name: gcComcercializacionVentaOutlineName,
            iconData: gcComcercializacionVentaOutline,
        },
    },
    datosCubierta: {
        solid: {
            name: gcDatosCubiertaName,
            iconData: gcDatosCubiertaSolid,
        },
        outline: {
            name: gcDatosCubiertaOutlineName,
            iconData: gcDatosCubiertaOutline,
        },
    },
    energiaUtilizada: {
        solid: {
            name: gcEnergiaUtilizadaName,
            iconData: gcEnergiaUtilizadaSolid,
        },
        outline: {
            name: gcEnergiaUtilizadaOutlineName,
            iconData: gcEnergiaUtilizadaOutline,
        },
    },
    pastoreo: {
        solid: {
            name: gcPastoreoName,
            iconData: gcPastoreoSolid,
        },
        outline: {
            name: gcPastoreoOutlineName,
            iconData: gcPastoreoOutline,
        },
    },
    actividadAgraria: {
        solid: {
            name: gcActividadAgrariaName,
            iconData: gcActividadAgrariaSolid,
        },
        outline: {
            name: gcActividadAgrariaOutlineName,
            iconData: gcActividadAgrariaOutline,
        },
    },
    planAbonado: {
        solid: {
            name: gcPlanAbonadoName,
            iconData: gcPlanAbonadoSolid,
        },
        outline: {
            name: gcPlanAbonadoOutlineName,
            iconData: gcPlanAbonadoOutline,
        },
    },
};

/**
 * Inicializa los iconos globalcampo al set de iconos de ionic
 */
export const initGlobalcampoIcons = () => {
    for (const iconNameKey in globalcampoIconos) {
        if (globalcampoIconos[iconNameKey]) {
            const iconValue = globalcampoIconos[iconNameKey];
            addIconTypes(iconValue);
        }

    }
};

/**
 * Añade los tipos de un icono al set de iconos de ionic
 * @param iconValue
 */
const addIconTypes = (iconValue: TIconType) => {
    for (const iconTypeKey in iconValue) {
        if (iconValue[iconTypeKey]) {
            const iconType = iconValue[iconTypeKey];
            addIcons(iconType.iconData);
        }
    }
};

/**
 * Añade los iconos indicados al set de iconos de ionic
 * @param icons
 */
export const addCustomIcons = (icons: TGlobalcampoIcon[]): void => {
    icons.forEach((icon) => {
        addIcons(icon);
    });
};
